<script>
import { v4 as uuidv4 } from 'uuid'
import { uploadFile } from '@/utils/cos'
export default {
  components:{
    'value-node':{
      functional: true,
      render(h,ctx) {
        return ctx.props.vnodes(h,ctx.props.item)
      }
    }
  },
  filters:{
    acceptFormat(val){
      if (!val || (Array.isArray(val)&&!val.length)){
        return 'image/*'
      }
      if (Array.isArray(val)&&val.length){
        const arr = val.map(e=> {
          if (e.includes('.')) return e
          return e +'/*'
        })
        const result = arr.join(',')
        return result
      }
    }
  },
  props:{
    labelWidth:{
      type: String,
      default:'6.2em'
    },
    isLabelTop:{
      type: Boolean,
      default: false
    },
    inputAlign:{
      type: String,
      default: 'right'
    },
    list:{
      type: Array,
      default:()=>{}
    },
    formData:{
      type: Object,
      default:()=>({})
    },
    isEdit:{
      type: [Boolean,String,Number],
      default: false
    },    
  },
  methods:{
    validate() {
      return this.$refs.editForm.validate()
    },
    vnodes(h,item) {
      return item.renderFn && item.renderFn(h,item)
    },
    getTagsValue(item) {
      const { formData } = this
      const { options,field } = item
      const obj = options.find(it=>{
        return (it[item.keys?.id || 'dictCode']) === formData[field]
      })
      return obj && obj[item.keys?.name || 'dictName']
    },
    // 上传前校验
    beforeRead(file,item) {
      // const str = file.name.substring(file.name.lastIndexOf('.') + 1)
      // const rules = item.format || ['jpg','png','jpeg']
      // if (!rules.includes(str.toLocaleLowerCase())) {
      //   this.$toast.fail(this.$t(`请上传正确文件(支持${rules.join('/')})`))
      //   return false
      // }
      if (item?.format?.length) {
        const str = file.name.substring(file.name.lastIndexOf('.'))
        const rules = item.format
        if (!rules.includes(str.toLocaleLowerCase()) && rules.findIndex((e)=>file.type.includes(e)) < 0) {
          this.$toast.fail(this.$t(`请上传正确文件(支持${rules.join('/')})`))
          return false
        }
      } else if (!file.type.includes('image')) {
        this.$toast.fail(this.$t('请上传正确图片文件'))
        return false
      }
      if (item.maxSize && file.size > item.maxSize * 1024 * 1024){
        this.$toast.fail(this.$t(`文件大小不能超过${item.maxSize}M`))
        return false
      }
      return true
    },
    // isPdf(fileUrl) {
    //   const url = fileUrl.split('?')[0]
    //   if (url){
    //     return url.substring(url.lastIndexOf('.') + 1) === 'pdf'
    //   }
    //   return false
    // },
    isFile(fileUrl) {
      const url = fileUrl.split('?')[0]
      if (url){
        return ['pdf','docx','txt', 'xlsx'].includes(url.substring(url.lastIndexOf('.') + 1))
      }
      return false
    },
    openPdf(fileUrl){
      if (this.$isMobile){
        window.location.href = fileUrl
      } else {
        window.open(fileUrl)
      }
    },
    afterRead(file,item) {
      const uuid = uuidv4()
      file.status = 'uploading'
      file.message = this.$t('上传中')
      file.uuid = uuid
      const content = file.content
      uploadFile(file.file).then(({ url }) => {
        if (file) {
          file.status = 'done'
          file.message = ''
          file.url = content
          file.cosUrl = url
          item.fileList.push(file)
        }  
        if (item.maxlength && item.maxlength) {
          this.$set(this.formData,item.field,(item.fileList.map(it=>it.cosUrl)).join(','))
        } else {
          this.$set(this.formData,item.field,url)
        }
      }).catch(() => {
        console.error('上传失败')
      })
    },
    beforeDelete(file,item) {      
      if (file.uuid){
        item.fileList = item.fileList.filter(it => it.uuid !== file.uuid)
      } else {
        item.fileList = item.fileList.filter(it => it.url !== file.url)
      }
      if (item.maxlength && item.maxlength) {
        this.$set(this.formData,item.field,(item.fileList.map(it=>it.url)).join(','))
      } else {
        this.$set(this.formData,item.field,'')
      }
    },
  }
}
</script>
<template>
<van-form ref="editForm" :class="{read: !isEdit,labelTop:isLabelTop}" :input-align="inputAlign" :readonly="!isEdit" error-message-align="right" :label-width="labelWidth" >
  <template v-for="(item,index) in list">   
    <template v-if="!item.isHidden">
      <van-field
        v-if="item.type === 'upload'"
        :key="item.field || index"
        :value="item.value || formData[item.field]"
        :label="item.label"
        readonly
        :required="item.required"
        :placeholder="$t('请完成上传')"
        :rules="[{required: true, trigger: 'onBlur'}]"
        class="upload"
        :class="{uploadIsDisabled: !isEdit}">
        <template #input>  
          <div>
            <template v-if="item.tips">
              <div class="tips">{{ item.tips }}</div>
            </template>
            <van-uploader
              :key="item.field || index"
              :file-list="item.fileList"
              :accept="item.format | acceptFormat"
              :preview-options="{closeable: true}"
              :before-read="(file)=>beforeRead(file,item)"
              :deletable="isEdit"
              :disabled="!isEdit"
              :after-read="(file) => afterRead(file,item)"
              :before-delete="(file) => beforeDelete(file,item)"
              :max-count="item.maxlength || 1">
              <template #preview-cover="file">
                <!-- <div v-if="isPdf(file.url)" class="preview-cover van-ellipsis" @click="openPdf(file.url)">
                  <img src="@/images/pdf.png" alt="">
                </div> -->
                <div v-if="isFile(file.url)" class="preview-cover van-ellipsis" @click="openPdf(file.url)">
                  <img src="@/images/file_icon.svg" alt="">
                </div>
              </template>
            </van-uploader>
            <div class="error-tips">{{$t('请完成上传')}}{{ item.label}}</div>
          </div>
        </template>
      </van-field>
      <template v-else-if="isEdit">
        <value-node v-if="item.renderFn" :key="index" :vnodes="vnodes" :item="item"></value-node>
        <van-field
          v-if="['select','date'].includes(item.type)"
          :key="item.field || index"
          :label="item.label"
          :required="item.required || false"
          :placeholder="$t('请选择')"
          right-icon="arrow"
          :value="item.value || formData[item.field]"
          readonly
          input-align="right"
          @click="()=>{
            item.click && isEdit && item.click(item.field,item)
          }"
          >
        </van-field>
        <div v-if="item.type === 'tags'" :key="item.field || index" class="common-cell">
          <span class="common-cell-label">{{item.label}}</span>
          <div class="common-cell-value flex-wrap-clue">
            <div v-for="(it,ind) in (item.options||[])"  :key="ind" class="btn-item" :class="{ commonActive: (it[item.keys?.id || 'dictCode']) === formData[item.field]}"  @click="isEdit && (formData[item.field] = it[item.keys?.id || 'dictCode'])">{{it[item.keys?.name || 'dictName']}}</div>
          </div>
        </div>
        <van-field
          v-if="['input', 'textarea', 'number'].includes(item.type)"
          :key="item.field || index"
          v-model.trim="formData[item.field]"
          :label="item.label"
          :type="item.type"
          :show-word-limit="item.type === 'textarea'"
          :maxlength="item.maxlength || 99999"
          :rules="item.rules || []"
          :required="item.required"
          :placeholder="$t('请输入')"
        />
        <van-field
          v-if="item.type === 'text'"
          :key="item.field || index"
          :value="item.value || formData[item.field]"
          :label="item.label"
          readonly
          :placeholder="$t('请输入')"
        />
      </template>        
      <van-field
          v-else
          :key="item.field || index"
          :type="['input', 'textarea', 'number'].includes(item.type)? item.type: 'input'"
          :value="item.type === 'tags'?getTagsValue(item) : (item.value || formData[item.field])"
          :label="item.label"
          readonly
          :placeholder="$t('暂无')"
          autosize
          :rows="item.rows || 1"
        />
    </template>
  </template>
</van-form>
</template>
<style lang="less" scoped>
.read{
  /deep/ .van-icon-arrow{
    display: none;
  }
}
.labelTop{
  /deep/ .van-field{
    display: block;
  }
}
.common-cell{
  position: relative;
  display: flex;
  font-size: 14px;
  padding: 10px 16px;
  &.required::before{
    position: absolute;
    top: 16px;
    left: 8px;
    color: #EED484;
    font-size: 15px;
    content: '*'
  }
  :not(:last-child)&::after{
    content: '';
    position: absolute;
    box-sizing: border-box;
    pointer-events: none;
    right: 16px;
    bottom: 0;
    left: 16px;
    border-bottom: 1px solid #ebedf0;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
  .common-cell-label{
    color: @black;
    width: 9.2em;
    margin-right: 12px;
    line-height: 32px;
  }
  .no-list{
    line-height: 32px;
  }
  .common-cell-value{
    flex: 1;
  }
  .flex-wrap-clue{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    .btn-item{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px 8px;
      // height: 24px;
      line-height: 1;
      color: @black;
      background-color: #F5F5F5;
      border-radius: 4px;
      font-size: 12px;
      cursor: pointer;
      &:not(:last-child){
        margin-right: 10px;
      }
      &.commonActive{
        color: #B9921A;
        background: rgba(238,212,132,0.45);
      }
    }
  }
}
.upload {
  /deep/ .van-uploader {
    .van-uploader__wrapper--disabled{
      opacity: 1;     
      
    }
  }
  .tips{
    color: rgba(100, 101, 102, 0.5);
    font-size: 13px;
  }
  .error-tips{
    color: #ee0a24;
    display: none;
  }
  &.van-field--error{
    .error-tips{
      display: block;
    }
  }
  .preview-cover{
    background: #fff;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.uploadIsDisabled{
  /deep/ .van-uploader {
    .van-uploader__upload{
      display: none;
    } 
  }
}
</style>
