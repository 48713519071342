<script>
import FormVue from '@/modules/components/form/index.vue'
import deliveryServices from '@/services/deliveryServices.js'
import { thenResolve } from '@/utils/index'
import orderServices from '@/services/orderServices'
export default {
  components:{ FormVue },
  data() {
    return {
      detail:{},
      taskDetail:{},
      info:[],
      info2:[],
      orderId:'',
      taskCode:'', // FINANCE_MATERIAL
      taskId: '',
      formData:{},
      contract: {} // 购车合同
    }
  },
  computed:{
    isEdit() {
      return this.taskDetail.taskStatus !== '4004003'
    }
  },
  created() {
    const { id,taskCode,taskId } = this.$route.query
    this.orderId = id
    // this.taskCode = taskCode
    this.taskId = taskId
    if (id && taskId){
      this.getDetail()
      this.deliveryTaskDetail()
      this.getContractDetails()
    }
  },  
  methods:{
    getInfo() {
      const { taskCode,detail,taskDetail } = this
      const { buyCarUrl ,loanUrl ,buyCarInvoiceUrl,carRegisterUrl } = taskDetail?.executionJson || {}
      this.info = [
        {
          label: '', // 贷款合同
          field:'loanUrl',
          value:'',
          fileList:getUrlArr(loanUrl),
          type:'upload',
          format: ['image','.pdf'],
          required: true
        },
      ]
      this.info2 = [
        {
          label: '', // 购车合同
          field:'buyCarUrl',
          value:'',
          fileList:getUrlArr(buyCarUrl),
          type:'upload',
          format: ['image','.pdf'],
          required: true,
          maxlength: this.isEdit ? 6 : getUrlArr(buyCarUrl).length,
        },
      ]
      function getUrlArr(str) {        
        const imgArr = []
        if (str){
          (str.split(',')).map((url=>{
            imgArr.push({
              status:'done',
              message:'',
              url
            })
          }))
        }
        return imgArr
      }
    },
    // 获取订单详情
    async getDetail() {
      const res = await deliveryServices.deliveryDetail({ id: this.orderId })
      this.detail = res || {}      
    },
    // 任务详情
    deliveryTaskDetail() {
      deliveryServices.deliveryTaskDetail({
        taskId: this.taskId
      }).then(res=>{
        this.taskCode = res.taskCode
        this.taskDetail = res
        this.getInfo()
      })
    },
    // 购车合同详情
    async getContractDetails() {
      const params = {
        orderId: this.orderId,
        contractType: 1, // 1 购车 2交车
      }
      const res = await orderServices.orderContractDetails(params)
      this.$set(this,'contract',res||{})
    },
    // 预览合同
    viewContract(){
      let url = this.contract.contractUrl
      if (this.$isMobile){
        window.location.href = url
      } else {
        window.open(url,'myWindow')
      }
    },
    // 取消
    goBack() {
      this.$router.go(-1)
    },
    onSubmit() {
      this.$refs.form.validate().then(res=>{
        const { taskCode,orderId,taskId,formData } = this
        if (!taskCode && !orderId && !taskId) return
        const { buyCarUrl ,loanUrl } = formData
        if ((!loanUrl || (!this.contract.contractUrl && !buyCarUrl))){
          return 
        }
        deliveryServices.deliveryExecution({
          executionJson:{
            taskCode,
            ...formData
          },
          id: taskId,
          taskStatus:'4004003',
        }).then(thenResolve).then(res=>{
          setTimeout(() => {
            this.$router.go(-1)
          }, 1000)
        })
      })
    },
  }
  
}
</script>
<template>
<div class="page">
 <div class="common-block item">
   <div class="common-t">
     <div class="common-t-l">{{$t('车辆信息')}}</div>
   </div>
   <!-- 姓名 -->
   <div class="vehicle">{{ detail.customerName }}</div>
   <div class="label">
     <div class="common-label-t" style="color: #FF7F50;">
        {{ detail.customerType == 1 ? $t('个人') : $t('企业') }} |
        {{ detail.installmentsFlag == 1 ? $t('分期') : $t('全款') }}
      </div>
   </div>
   <!-- 车系/车型/外饰/内饰 -->
   <div class="label">
     <div class="common-label-t">
      {{ detail.carOrderMaterial?.seriesName }} |
      {{ detail.carOrderMaterial?.modelName }} |
      {{ detail.carOrderMaterial?.colourName }} |
      {{ detail.carOrderMaterial?.interiorName }} 
    </div>
   </div>
     <!-- <div class="common-label-c">{{ detail.carOrderMaterial?.interiorName }}</div> -->
   
   <div class="label">
     <div class="common-label-t">VIN : {{ detail.vin }}</div>
   </div>
 </div>
 <!-- 购车合同 -->
 <div class="common-block item">
   <div class="common-t">
     <div class="common-t-l">{{$t('购车合同')}}</div>
   </div>
   <van-field v-if="contract.contractUrl" label="购车合同已同步" inputAlign="right">
    <template #input>
      <div class="view" @click="viewContract">查看合同详情</div>
    </template>
    </van-field>
    <div v-else>
      <div class="tips">本订单无线上合同副本，请手动拍照合同（含补充单）进行上传</div>
      <FormVue ref="form" class="formVue" labelWidth="8.2em" :isLabelTop="true" :isEdit="isEdit" inputAlign="left" :list="info2" :formData="formData"/> 
    </div>
 </div>
 <!-- 贷款合同 -->
 <div class="common-block item">
   <div class="common-t">
     <div class="common-t-l">{{$t('贷款合同上传')}}</div>
   </div>
   <FormVue ref="form" class="formVue" labelWidth="8.2em" :isLabelTop="true" :isEdit="isEdit" inputAlign="left" :list="info" :formData="formData"/> 
 </div>
 <div v-if="isEdit" class="common-footer">
     <van-button class="btn back" native-type="button" @click.stop="goBack">{{$t('返回')}}</van-button>
     <van-button class="btn submit" type="submit" @click="onSubmit">{{$t('提交')}}</van-button>
 </div>
</div>
</template>
<style lang="less" scoped>
.page{
  padding: 16px;
  padding-bottom: 96px;
}
.item{
  padding:0 12px 12px;
  color: @black;
  .common-t-l{
    &.required::after{
      content: '*';
      color: #EED484;
    }
  }
  &:not(:last-child){
    margin-bottom: 16px;
  }
  .vehicle{
    font-size: 16px;
    margin: 12px 0;
  }
  .label{
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  }
}
.formVue{
  /deep/ .van-field{
    padding: 10px 0;
    &::before{
      left: -6px;
    }
    .van-cell__title{
      margin-bottom: 12px;
    }
  } 
}
.view{
  color: #FF7F50;
  cursor: pointer;
}
.tips{
  font-size: 14px;
  color: gray;
  margin-top: 12px;
}
</style>
