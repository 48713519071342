import COS from 'cos-js-sdk-v5'
import { v4 as uuidv4 } from 'uuid'
import { getExtName } from './index'
import store from '@/store'

export function waitForInitData() {
  return new Promise((resolve, reject) => {
    // 检查 Vuex 中是否有数据
    if (store.getters.cosConfig.VUE_APP_COS_SECRET_ID) {
      // 如果有数据，直接resolve
      console.log('有数据')
      resolve(store.getters.cosConfig) 
    } else {
      console.log('没数据，走监听')
      // 如果没有数据，则监听数据变化
      const unsubscribe = store.subscribe((mutation, state) => {
        if (mutation.type === 'app/setCosConfig') {
          unsubscribe() // 取消监听
          resolve(mutation.payload) // 数据改变后resolve
        }
      })
    }
  })
}

let cos, Region, Bucket

waitForInitData().then(data=>{
  const {
    VUE_APP_COS_SECRET_ID: SecretId,
    VUE_APP_COS_SECRET_KEY: SecretKey,
    VUE_APP_COS_REGION,
    VUE_APP_COS_BUCKET
  } = data
  cos = new COS({
    SecretId,
    SecretKey
  })
  Region = VUE_APP_COS_REGION
  Bucket =VUE_APP_COS_BUCKET
})

// 删除单个对象
export const cosDeleteFile = (Key) => {
  cos.deleteObject({
    Bucket, /* 填入您自己的存储桶，必须字段 */
    Region, /* 存储桶所在地域，例如ap-beijing，必须字段 */
    Key, /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
  }, function (err, data) {
    console.log(err || data)
  })
}
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
export const uploadFile = (file, { onProgress = () => { }, Headers = {} } = {}) => {
  return new Promise((resolve, reject) => {
    const index = file.name.lastIndexOf('.')
    const name = file.name.substring(0, index)
    const myKey = `qw-scrm-h5/${uuidv4().replace(/-/g, '')}_${name}${getExtName(file.name)}`
    cos.putObject(
      {
        Bucket,
        Region,
        Key: myKey,
        Body: file,
        // Headers,
        onProgress
      },
      async (err, data) => {
        if (err) {
          reject(err)
          return
        }
        resolve({
          url: `https://${data.Location}`,
          Key: myKey,
          previewUrl: await getBase64(file)
        })
      }
    )
  })
}
export const cosDownload = (arg, name) => {
  return new Promise((resolve, reject) => {
    // const Key = decodeURIComponent(arg)
    const Key = decodeURIComponent(arg).split('.com/')[1]
    const str = Key.substring(Key.lastIndexOf('/') + 1)
    const fileName = encodeURIComponent(str.substring(str.indexOf('_') + 1))
    cos.getObjectUrl({
      Bucket: arg.substring(arg.indexOf('//') + 2, arg.indexOf('.cos')),
      Region: arg.substring(arg.indexOf('cos.') + 4, arg.indexOf('.myqcloud')),
      Key,
    }, (err, data) => {
      if (err) reject(err)
      const downloadUrl = data.Url + (data.Url.indexOf('?') > -1 ? '&' : '?') + 'response-content-disposition=attachment;filename=' + (name || fileName) + '&response-content-encoding=utf-8'// 补充强制下载的参数
      resolve(downloadUrl)
    })
  })
}
