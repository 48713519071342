import request from '@/utils/request'
import SERVER from './server'
import { Toast } from 'vant'
import { thenResolve } from '@/utils/index'

const orderServices = {
  /**
   * 订单列表
  */
  async getOrderList(params) {
    return await request.post(`${SERVER.ORDER_SERVICE}/api/v1/order/page`, params).then(thenResolve)
  },
  /**
   * 订单列表计数统计
  */
  async getOrderListCount(params) {
    return await request.post(`${SERVER.ORDER_SERVICE}/api/v1/order/pageCount`, params).then(thenResolve)
  },
  /**
   * 获取订单详情
  */
  async getOrderDetail(params) {
    return await request.get(`${SERVER.ORDER_SERVICE}/api/v1/order/detail`, { params }).then(thenResolve)
  },
  /**
   * 订单分配
   */
  async orderAssign(params) {
    return await request.post(`${SERVER.ORDER_SERVICE}/api/v1/order/order-assign`, params).then(thenResolve)
  },
  /**
   * 合同查询
   */
  async orderContract(params) {
    return await request.get(`${SERVER.ORDER_SERVICE}/api/v1/order/contract-get`, { params }).then(thenResolve)
  },
  /**
   * 合同确认 contractType:1 购车 2交车
   */
  async orderContractConfirm(orderId, contractType) {
    return await request.post(`${SERVER.ORDER_SERVICE}/api/v1/order/contract-confirm?orderId=${orderId}&contractType=${contractType}`).then(thenResolve)
  },
  /**
   * 订单跟进
   */
  async orderFollow(params) {
    return await request.post(`${SERVER.ORDER_SERVICE}/api/v1/order/order-follow`, params).then(thenResolve)
  },
  /**
   * 合同详情
   */
  async orderContractDetails(params) {
    return await request.get(`${SERVER.ORDER_SERVICE}/api/v1/order/contract-detail`, { params }).then(thenResolve)
  },
  /**
   * 申请修改订单
   */
  async orderUpdateApply(params) {
    return await request.post(`${SERVER.ORDER_SERVICE}/api/v1/orderUpdate/apply`, params)
  },
  /**
   * 提交审核
   */
  async orderUpdateCommit(params) {
    return await request.post(`${SERVER.ORDER_SERVICE}/api/v1/orderUpdate/commit?orderId=${params.orderId}`)
  },
  /**
   * 订单修改详情
   */
  async orderUpdateDetail(params) {
    return await request.get(`${SERVER.ORDER_SERVICE}/api/v1/orderUpdate/detail`, { params }).then(thenResolve)
  },
  /**
   * 取消修改
   */
  async orderUpdateCancel(params) {
    return await request.post(`${SERVER.ORDER_SERVICE}/api/v1/orderUpdate/cancel?orderId=${params.orderId}`)
  },
  /**
   * 审批前校验
   */
  async orderUpdateAuditCheck(params) {
    return await request.post(`${SERVER.ORDER_SERVICE}/api/v1/orderUpdate/audit-check`, params)
  },
  /**
   * 审批
   */
  async orderUpdateAudit(params) {
    return await request.post(`${SERVER.ORDER_SERVICE}/api/v1/orderUpdate/audit`, params)
  },
  /**
   * 一键催办
   */
  async orderUpdateRemind(params) {
    return await request.post(`${SERVER.ORDER_SERVICE}/api/v1/orderUpdate/remind?orderId=${params.orderId}`)
  },
  /**
   * 查询订单编辑待审核列表
   */
  async orderUpdateList(params) {
    return await request.post(`${SERVER.ORDER_SERVICE}/api/v1/orderUpdate/list`, params).then(thenResolve)
  },
  /**
   * 合同历史状态获取
   */
  async getHistoryState(params) {
    return await request.get(`${SERVER.ORDER_SERVICE}/api/v1/order/order-status-history`, { params }).then(thenResolve)
  },

  /**
   * 统一社会信用代码 （营业执照）
   */
  bizLicenseOCR(params) {
    return request.post(`${SERVER.ORDER_SERVICE}/api/v1/orderUpdate/ocr/bizLicense`, params).then(thenResolve)
  },

  /**
   * 身份证
   */
  idCardOCR(params) {
    return request.post(`${SERVER.ORDER_SERVICE}/api/v1/orderUpdate/ocr/idCard`, params).then(thenResolve)
  },

  // 转交付备注
  escrowRemark(params) {
    return request.post(`${SERVER.ORDER_SERVICE}/api/v1/orderUpdate/escrowRemark`, params).then(thenResolve)
  },
  // 特殊需求备注
  specialRemark(params) {
    return request.post(`${SERVER.ORDER_SERVICE}/api/v1/order/special-remark`, params).then(thenResolve)
  },
  // 申请退单
  applyRefundOrder(params) {
    return request.post(`${SERVER.ORDER_SERVICE}/api/v1/orderUpdate/apply-refund`, params).then(thenResolve)
  },
  // 退单的申请撤回/取消修改
  refundOrderCancel(params) {
    return request.post(`${SERVER.ORDER_SERVICE}/api/v1/orderUpdate/refund-order-cancel`, params).then(thenResolve)
  },
  // 退单催办
  remindRefund(params) {
    return request.post(`${SERVER.ORDER_SERVICE}/api/v1/orderUpdate/remind-refund`, params).then(thenResolve)
  },
  // 退单审批
  refundOrderAduit(params) {
    return request.post(`${SERVER.ORDER_SERVICE}/api/v1/orderUpdate/refund-order-audit`, params).then(thenResolve)
  },
  // 下载待签署合同
  downContractTem(params) {
    return request.post(`${SERVER.ORDER_SERVICE}/api/v1/orderReview/download-contract-temp`, params, { responseType: 'blob' }).then((res) => {
      if (res.headers['content-type'] === 'application/json') { // 下载失败 json返回
        const file = new FileReader()
        file.readAsText(res.data, 'utf-8')
        file.onload = function() {
          const obj = JSON.parse(file.result)
          if (obj && obj.code !== 0) {
            Toast(obj.msg || obj.message)
          }
          throw new Error(obj?.data || obj.code || obj.msg || obj.message || '')
        }
      } else {
        return res
      }
    })
  }
}
export default orderServices
